try {
    window.$ = window.jQuery = require('jquery');
} catch (e) {
}

require("bootstrap");

window.toastr = require("toastr/toastr");

require('./orderForm');
require('./calc');
require('./register');

window.inputmask = require('inputmask/dist/jquery.inputmask.min');
jQuery('input[name="phone"]').inputmask('+375 (99) 999-99-99', {placeholder: "+375 (__) ___-__-__"});

$(document).ready(() => {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    window.notifySuccess = (message, title) => {
        console.log(message)
        toastr.success(message, title, {timeOut: 1500});
    };

    window.notifyWarning = (message, title) => {
        console.log(message)
        toastr.warning(message, title, {timeOut: 1500});
    };

    window.notifyError = (message = 'Что-то пошло не так :(', title) => {
        console.log(message)
        toastr.error(message, title, {timeOut: 1500});
    };

    $('.custom-file-input').on('change', e => {
        let fileName = e.target.files[0].name;
        let nextSibling = e.target.nextElementSibling
        nextSibling.innerText = fileName
    })
})




