$(function(){

    $('body').on('click', '.js-teeth-link', e => {
        let _this = $(e.target);

        _this.toggleClass('_active');

        if (_this.hasClass('_active')) {
            $.ajax({
                type: "POST",
                url: $('#teeth_container').data('action'),
                data: {
                    service_id: $('select[name="service_id"]').val(),
                    tooth_id: _this.data('id'),
                },
                dataType: 'json',
                beforeSend: () => {},
                success: function (response) {
                    $('#selected_teeth').append(response.html)
                    $('#total_amount').html(response.order.amount)

                    $('.js-teeth-top-num').html(response.order.top_count);
                    $('.js-teeth-bottom-num').html(response.order.bottom_count);

                    notifySuccess(response.message);
                },
                error: (xhr) => {
                    notifyError();
                }
            });
        } else {
            $.ajax({
                type: "POST",
                url: _this.data('remove-action'),
                dataType: 'json',
                success: function (response) {
                    $(`#tooth_${response.tooth_id}`).remove()
                    $(`#jaw_tooth_${response.tooth_id}`).removeClass('_active')
                    $('#total_amount').html(response.order.amount)

                    $('.js-teeth-top-num').html(response.order.top_count);
                    $('.js-teeth-bottom-num').html(response.order.bottom_count);

                    notifySuccess(response.message);
                },
                error: (xhr) => {
                    notifyError();
                }
            });
        }

    })

    $('body').on('click', '.remove_tooth', e => {
        e.preventDefault();

        let element

        if ($(e.target).prop('nodeName') === 'I') {
            element = $(e.target).parent()
        } else {
            element = $(e.target)
        }

        $.ajax({
            type: "POST",
            url: element.data('action'),
            dataType: 'json',
            success: function (response) {
                $(`#tooth_${response.tooth_id}`).html(response.html)

                $(`#tooth_${element.data('id')}`).remove()
                $(`#jaw_tooth_${element.data('id')}`).removeClass('_active')
                $('#total_amount').html(response.order.amount)

                $('.js-teeth-top-num').html(response.order.top_count);
                $('.js-teeth-bottom-num').html(response.order.bottom_count);

                notifySuccess(response.message);
            },
            error: (xhr) => {
                notifyError();
            }
        });
    })

    $('select[name="category_id"]').on('change', e => {
        e.preventDefault()

        let value = $(e.target).val()

        if (value < 1) {
            $('select[name="service_id"]').attr('disabled', true).val(0)
            return;
        }

        $.ajax({
            type: "POST",
            url: $(e.target).data('action'),
            data: {
                category_id: $(e.target).val()
            },
            dataType: 'json',
            beforeSend: () => {
                $('select[name="service_id"]').attr('disabled', true).val(0)
            },
            success: function (response) {
                $('select[name="service_id"]').html(response.html).attr('disabled', false)

                notifySuccess(response.message);
            },
            error: (xhr) => {
                notifyError();
            }
        });
    })

    $('body').on('change', '.selected_tooth_service, .selected_tooth_color, .selected_tooth_supra', e => {
        e.preventDefault()

        let toothId = $(e.target).data('id')

        $.ajax({
            type: "POST",
            url: $(e.target).data('action'),
            data: {
                service_id: $(`#tooth_service_${toothId}`).val(),
                color: $(`#tooth_color_${toothId}`).val(),
                supra: $(`#tooth_supra_${toothId}`).val(),
            },
            dataType: 'json',
            success: function (response) {
                $(`#tooth_${response.tooth_id}`).html(response.html)
                $('#total_amount').html(response.order.amount)

                notifySuccess(response.message);
            },
            error: (xhr) => {
                notifyError();
            }
        });
    })
})
