$(document).ready(function () {

    $('.rebuild_order_modal').on('click', e => {
        let id = $(e.target).data('id')

        $('.rebuild_order_modal_title').text(id)
        $('textarea[name="rebuild_order_comment"]').val('')
        $('input[name="order_id"]').val(id)
    })

    $('#rebuild_order_send').on('click', e => {
        $.ajax({
            type: "POST",
            url: $(e.target).data('action'),
            data: {
                id: $('input[name="order_id"]').val(),
                comment: $('textarea[name="rebuild_order_comment"]').val(),
            },
            dataType: 'json',

            success: function (response) {
                notifySuccess(response.message);

                $('textarea[name="rebuild_order_comment"]').val('')
                $('input[name="order_id"]').val()

                $('#rebuild_order_modal').modal('hide')
            },
            error: (xhr) => {
                notifyError();
            }
        })
    })

});
