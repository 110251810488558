$(function(){
    $('#client_type').on('change', e => {
        e.preventDefault()

        let value = $(e.target).val()

        if (value === 'dentist') {
            $('.register_title_type').html('клиники')
            $('.fio_comment').html('врача')
        }

        if (value === 'dental_technician') {
            $('.register_title_type').html('фрезерного центра')
            $('.fio_comment').html('зубного техника')
        }
    })
})
